<template>
  <div>
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <div class="rn-column-area rn-section-gap mt--150">
      <v-container>
        <v-form ref="form"
        lazy-validation>
          <center><h3 class="mb--100"><b>Προφίλ</b></h3></center>

          <br>
         <v-text-field
            :label="user.mobile"
            v-model="mobile"
            persistent-hint
            disabled  
            outlined
            shaped
          ></v-text-field>
          <v-text-field
            v-model="user.full_name"
            label="Ονοματεπώνυμο"
            :rules="[rules.required]"
            outlined
            shaped
          ></v-text-field>
           <v-text-field
            v-model="user.email"
            outlined
            shaped
            :rules="[rules.required, rules.emailRules]"
            label="Email"
          ></v-text-field>
                <v-text-field
                v-model="user.birth_date"
                label="Ημερομηνία Γέννησης"
                prepend-icon="mdi-calendar"
                readonly
              ></v-text-field>
                <v-radio-group
                v-model="user.gender"
                  row
                >
                  <v-radio
                    label="Άντρας"
                    value="male"
                    name="gender"
                  ></v-radio>
                  <v-radio
                    label="Γυναίκα"
                    value="female"
                    name="gender"
                  ></v-radio>
                  <v-radio
                    label="Άλλο"
                    value="other"
                    name="gender"

                  ></v-radio>
                </v-radio-group>

                <div v-for="user_notification in user.user_notifications" :key="user_notification.type">
                    <v-checkbox
                      v-model="user_notification.active"
                      :label="`Αποδοχή ειδοποιήσεων ${user_notification.type.toString()}`"
                    ></v-checkbox>
                  </div>  
          <v-btn
            color="primary"
            dark
            class="mr-4"
            @click="updateProfile"
          >
            Αποθήκευση
          </v-btn>
        </v-form>
          
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";
  import { validationMixin } from 'vuelidate'
  import { required, maxLength, email } from 'vuelidate/lib/validators'


  export default {
    components: {
      Header,
      Footer,
    },

    data: () => ({
      results: [],
      full_name: '',
      name: '',
      mobile: '',
      gender: '',
      email: '',
      checkbox: false,
      sms: false,
      mail: false,
      push: false,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      rules: {
        required: value => !!value || 'Το πεδίο είναι υποχρεωτικό.',
        emailRules: v => (!v || /.+@.+\..+/.test(v)) || 'Το email δεν είναι έγγυρο.',
      },
    }),
    computed: {
      ...mapGetters (["user"]),
    },
    async  created () {
      await this.getUser();
      if(!this.user){
         this.$router.push('/')
         return
      }
    },
    methods: {
      ...mapActions(["getUser"]),
   
      async updateProfile(e){

        try{
          if(this.$refs.form.validate()){
               e.preventDefault();
              this.errors = '';
              const response = await axios.post('external/me', 
              {
                  full_name: this.user.full_name,
                  gender: this.user.gender,
                  email: this.user.email,
                  data:   
                  [
                    {
                      user_notification_setting_id: this.user.user_notifications[0].user_notification_setting_id,
                      active: this.user.user_notifications[0].active
                    },
                    {
                      user_notification_setting_id: this.user.user_notifications[1].user_notification_setting_id,
                      active: this.user.user_notifications[1].active
                    },
                    {
                      user_notification_setting_id: this.user.user_notifications[2].user_notification_setting_id,
                      active: this.user.user_notifications[2].active
                    }
                  ]
              }).then(response => {
                  this.$swal({
                          title: "Συγχαρητήρια",
                          text: "Το προφίλ σας ενημερώθηκε επιτυχώς.",
                          icon: "success", //built in icons: success, warning, error, info
                          timer: 3000, //timeOut for auto-close
                            buttons: {
                              confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                              },
                              cancel: {
                                text: "Cancel",
                                value: false,
                                visible: true,
                                className: "",
                                closeModal: true,
                              }
                            }
                        });
              }).catch(error => {
                  console.log(error.response.data)
                  if (error.response.data.code == 401) {
                      this.errors = "Παρακαλώ ελέγξτε τα στοιχεία σας.";
                  } 
              }); 
            
          }else{
            return false;
          }            
        }catch(e){
            this.error = e.message;
            return e;
        }
             
      }
    },
  }
</script>
